<template>
  <div>
    <UITabs v-model="activePage" :tabs-list="tabsList" />
  </div>
</template>

<script>
import UITabs from '@src/components/UiElements/UITabs.vue'

export default {
  components: {
    UITabs,
  },
  data() {
    return {
      tabsList: [
        { key: 'dashboard', name: 'Dashboard' },
        { key: 'feature-permission', name: 'Feature Permission' },
        { key: 'system-settings', name: 'System Settings' },
        { key: 'notifications', name: 'Notifications' },
        { key: 'features', name: 'Features' },
        { key: 'ownership', name: 'Ownership' },
      ],
      activePage: 'dashboard',
    }
  },
}
</script>
