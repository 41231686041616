<template>
  <div class="flex gap-3 flex-nowrap firefoxborder overflow-x-scroll">
    <button
      v-for="tab in tabsList"
      :key="tab.key"
      :class="{
        'text-primary-purple-600 bg-primary-purple-50 border-primary-purple-600 border-b-2':
          activeTab === tab.key,
        'text-primary-gray-500': activeTab !== tab.key,
      }"
      class="p-3 h-11 text-sm font-semibold transition duration-300 min-w-max"
      @click="changePage(tab.key)"
    >
      {{ tab.name }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    tabsList: {
      type: Array,
      required: true,
    },
    modelValue: {
      default: '',
      type: String,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      activeTab: null,
    }
  },
  watch: {
    activeTab: {
      handler(tab) {
        this.$emit('update:modelValue', tab)
      },
    },
  },
  created() {
    this.initTabData()
  },

  methods: {
    initTabData() {
      if (this.modelValue) this.activeTab = this.modelValue
      else this.activeTab = this.tabsList.length && this.tabsList[0].key
    },
    changePage(key) {
      this.activeTab = key
    },
  },
}
</script>

<style scoped>
@-moz-document url-prefix() {
  .firefoxborder {
    overflow-x: auto;
    border-bottom: 1px solid var(--primary-gray-200);
  }
}
</style>
